import axios from "axios";
import { MarkerType } from "reactflow";
import { GlobalConfig } from "../helpers";
import * as notificationService from "../Services/notifications";

export const API_Request = (method, url, body) => {
  // notificationService.showInfoNotification('Generando peticion');
  let url_aux =
    GlobalConfig.API_BACKEND + (url.substring(0, 1) === "/" ? url : "/" + url);
  return axios({
    headers: {
      "Content-Type": "application/json",
      apiKey: window.localStorage.getItem("apiKey"),
    },
    maxBodyLength: Infinity,
    method: method.toLowerCase(),
    url: url_aux,
    data: body,
  })
    .then((result) => {
      return result;
    })
    .catch((error) => {
      notificationService.showErrorNotification(
        "An error non expected, " + error.message
      );
      console.log(error.message);
      return error;
    });
};

export const formatSpanishDate = (input, explicit = false) => {
  if (typeof input === "undefined") {
    return "";
  }

  const date =
    typeof input === "string" ? new Date(input) : new Date(input * 1000);
  const currentDate = new Date();

  if (explicit) {
    const day = date.getDate();
    const month = date.toLocaleString("es-ES", { month: "long" });
    const year = date.getFullYear();
    const time = date.toLocaleTimeString("es-ES", {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    });

    return `${day} de ${month} de ${year} ${time}`;
  }

  const timeDifference = currentDate.getTime() - date.getTime();
  const minutesDifference = Math.floor(timeDifference / (1000 * 60));

  if (date.toDateString() === currentDate.toDateString()) {
    if (minutesDifference <= 0) {
      return `Now`;
    }
    if (minutesDifference < 5) {
      return `${minutesDifference} minutes ago...`;
    } else {
      return `Today ${date.toLocaleTimeString("es-ES", {
        hour: "2-digit",
        minute: "2-digit",
      })}h`;
    }
  } else {
    const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    if (daysDifference > 1) {
      return `${daysDifference} days ago ${date.toLocaleTimeString("es-ES", {
        hour: "2-digit",
        minute: "2-digit",
      })}h`;
    } else {
      return `Yesterday ${date.toLocaleTimeString("es-ES", {
        hour: "2-digit",
        minute: "2-digit",
      })}h`;
    }

    //return date.toLocaleString('es-ES', {
    //  day: 'numeric',
    //  month: 'long',
    //  year: 'numeric',
    //  hour: '2-digit',
    //  minute: '2-digit',
    //  second: '2-digit'
    //});
  }
};

export const fetchAndSerializeFlow = async ({ id }) => {
  try {
    const response = await API_Request("GET", `/bots/${id}`);
    const serializedNodes = [];
    const serializedEdges = [];
    response.data.forEach((element) => {
      element.nodes.forEach((_node) => {
        _node.position = { x: _node.x, y: _node.y };

        delete _node.x;
        delete _node.y;
        let payload_aux = [];
        if (_node.payload & (typeof _node.payload === String)) {
          payload_aux = JSON.parse(_node.payload);
        } else {
          payload_aux = _node.payload;
        }
        serializedNodes.push({
          type: _node.node_type,
          id: "" + _node.id + "",
          position: _node.position,
          data: {
            wait: _node.wait,
            typewritting: _node.typewritting,
            delay: _node.delay,
            id: _node.id,
            name: payload_aux?.name ?? "",
            type: _node.node_type,
            payload: payload_aux,
          },
        });
        _node.inputs.forEach((element_connection) => {
          serializedEdges.push({
            id: element_connection.id,
            source: "" + _node.id + "",
            target: "" + element_connection.node + "",
            label: element_connection.name,
            markerEnd: {
              type: MarkerType.ArrowClosed,
              width: 12,
              height: 12,
              color: element_connection.color || "#99a1e5",
            },
            style: {
              strokeWidth: 2,
              stroke: element_connection.color || "#99a1e5",
            },
          });
        });
        _node.outputs.forEach((element_connection) => {
          serializedEdges.push({
            id: element_connection.id,
            source: "" + _node.id + "",
            sourceHandle: element_connection.sourceHandle,
            target: "" + element_connection.node + "",
            label: element_connection.name,
            type: element_connection.type || "smoothstep",
            markerEnd: {
              type: MarkerType.ArrowClosed,
              width: 12,
              height: 12,
              color:
                element_connection.sourceHandle &&
                element_connection.sourceHandle === "false"
                  ? "#ff0000"
                  : element_connection.color || "#99a1e5",
            },
            style: {
              strokeWidth: 2,
              stroke:
                element_connection.sourceHandle &&
                element_connection.sourceHandle === "false"
                  ? "#ff0000"
                  : element_connection.color || "#99a1e5",
            },
          });
        });
      });
    });
    return { serializedNodes, serializedEdges };
  } catch (error) {
    console.log(error);
  }
};
