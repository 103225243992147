export const GlobalConfig = {
  API_BACKEND: process.env.REACT_APP_API_BACKEND,
  NODE_ENV: process.env.REACT_APP_NODE_ENV || "PRO",
};

export const EXIT_BUILDER_MESSAGE =
  "Tienes cambios sin guardar. ¿Seguro que quieres salir?";
export const TEMPORAL_NODE_ID_PREFIX = "temporal";

export function hsvToHex(h, s, v) {
  // Verifica si los valores no son números o están fuera de rango
  if (
    typeof h !== "number" ||
    typeof s !== "number" ||
    typeof v !== "number" ||
    h < 0 ||
    h > 360 ||
    s < 0 ||
    s > 1 ||
    v < 0 ||
    v > 1
  ) {
    console.error("Valores HSV no válidos:", h, s, v);
    return "#000000"; // Valor predeterminado en caso de error
  }

  h = ((h % 360) + 360) % 360;
  s = Math.min(1, Math.max(0, s));
  v = Math.min(1, Math.max(0, v));

  const c = v * s;
  const x = c * (1 - Math.abs(((h / 60) % 2) - 1));
  const m = v - c;

  let r, g, b;

  if (0 <= h && h < 60) {
    r = c;
    g = x;
    b = 0;
  } else if (60 <= h && h < 120) {
    r = x;
    g = c;
    b = 0;
  } else if (120 <= h && h < 180) {
    r = 0;
    g = c;
    b = x;
  } else if (180 <= h && h < 240) {
    r = 0;
    g = x;
    b = c;
  } else if (240 <= h && h < 300) {
    r = x;
    g = 0;
    b = c;
  } else {
    r = c;
    g = 0;
    b = x;
  }

  const intR = Math.round((r + m) * 255);
  const intG = Math.round((g + m) * 255);
  const intB = Math.round((b + m) * 255);

  const hexR = intR.toString(16).padStart(2, "0");
  const hexG = intG.toString(16).padStart(2, "0");
  const hexB = intB.toString(16).padStart(2, "0");

  return `#${hexR}${hexG}${hexB}`;
}
