import React, { useState, useEffect } from "react";
import { API_Request } from "../Services/APIService";
import DataTable from "./../Components/DataTable";
import { SettingOutlined, PullRequestOutlined } from "@ant-design/icons";
import { useParams } from "react-router-dom";
import { FormBuilder } from "../Components/FormBuilder";
import { Spin, Tooltip, Row, Col, Modal } from "antd";
import { GlobalConfig } from "../helpers";
import * as Icons from "@ant-design/icons";
import { FormSecondaryWindow } from "./../Components/FormSecondaryWindow";
import { showSuccessNotification } from "../Services/notifications";

const dataTable_params = {
  entity: "flows",
  main: "name",
  rows: 50,
  fieldsForm: [
    { name: "id" },
    { name: "tag", column: true },
    { name: "name", required: true },
    { name: "flow_type", label: "Type", required: true },
    { name: "session_time" },
    { name: "enabled", label: "Enabled" },
    { name: "bot_enabled", label: "With Bot" },
    { name: "idbot", label: "InBound Bot Linked" },
    { name: "outboundConfig", label: "OutBound Config" },
    { name: "url_commands", label: "External Commands" },
  ],

  actions: [
    {
      icon: <SettingOutlined />,
      link: "flows",
      title: "Settings",
    },
    {
      icon: <PullRequestOutlined />,
      link: "bots/builder",
      title: "Builder",
    },
  ],
};

const dataTable_params_security = {
  entity: "security",
  main: "name",
  key: "id",
  rows: 10,
  fieldsForm: [
    { name: "id", label: "Id", info: "Explicacion", required: true },
    { name: "name", info: "Name of secure", required: true },
    {
      name: "secure",
      label: "Value to secure",
      info: "nombre de origen * para todo | 0.0.0.0",
      required: false,
    },
  ],
  pagination: false,
  actions: [
    {
      icon: <SettingOutlined style={{ fontSize: "20px" }} />,
      link: "flows/security",
    },
  ],
};
const dataTable_params_webhook = {
  entity: "webhook",
  main: "name",
  key: "id",
  rows: 10,
  fieldsForm: [
    { name: "id", label: "Id", required: true },
    { name: "name", required: true },
    { name: "type", label: "Type", required: false },
    { name: "webhook", label: "Webhook", info: "Explicacion", required: false },
  ],
  pagination: false,
  actions: [
    {
      icon: <SettingOutlined style={{ fontSize: "20px" }} />,
      link: "flows/webhook",
    },
  ],
};

const dataTable_params_templates = {
  entity: "templates",
  main: "name",
  key: "id",
  rows: 20,
  create: false,
  fieldsForm: [
    { name: "id", label: "Id", required: true },
    { name: "status", column: true },
    { name: "elementName", label: "Type", column: true },
    { name: "data", label: "Webhook", info: "Explicacion", column: true },
  ],
};

const FlowsPage = () => {
  const [loading, setLoading] = useState(true);
  const [isSecondaryWindowVisible, setSecondaryWindowVisible] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [send_templateModalVisible, setSend_templateModalVisible] =
    useState(false);

  const [secodary_data, setSecodary_data] = useState({});
  const [data, setData] = useState([]);
  const { id, module } = useParams();
  const entity = "Flows";

  const handle_Send_templateModal = (data) => {
    setSend_templateModalVisible(true);
  };

  const handle_CloseSecodaryWindow = () => {
    setSecondaryWindowVisible(false);
  };
  const handle_OpentoCreateSecodaryWindow = (data) => {
    setSecodary_data(data);
    setSecondaryWindowVisible(true);
  };

  const handle_RowSecondaryWindow = (data) => {
    setSecodary_data(data);
    setSecondaryWindowVisible(true);
  };

  const handle_SaveSecodaryWindow = (_data) => {
    setSecondaryWindowVisible(false);

    if (_data.id) {
      let url = "flows/" + _data.entity + "/" + _data.id;
      API_Request("PUT", url, _data.payload)
        .then((result) => {
          showSuccessNotification("Data update!!!");
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {});
    } else {
      let url = "flows/" + _data.entity + "/" + data.id;
      API_Request("POST", url, _data.payload)
        .then((result) => {
          showSuccessNotification("Data saved!!!");
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {});
    }
  };

  const handle_DeleteConfirm = () => {
    let url = "flows/" + secodary_data.entity + "/" + secodary_data.id;

    API_Request("DELETE", url)
      .then((result) => {
        let newData = {};

        if (secodary_data.entity === "security") {
          newData = {
            ...data,
            webhook: data.security.filter(
              (item) => item.id !== secodary_data.id
            ),
          };
        } else {
          newData = {
            ...data,
            webhook: data.webhook.filter(
              (item) => item.id !== secodary_data.id
            ),
          };
        }
        setData(newData);
        showSuccessNotification("Data erased!!!");
        setDeleteModalVisible(false);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {});
  };

  const handle_DeleteCancel = () => {
    setDeleteModalVisible(false);
  };

  const handle_DeleteSecodaryWindow = (data) => {
    setSecondaryWindowVisible(false);
    setDeleteModalVisible(true);
  };

  const handleCopyToClipboard = (value) => {
    const textArea = document.createElement("textarea");
    textArea.value = value;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand("copy");
    document.body.removeChild(textArea);
  };

  useEffect(() => {
    setLoading(true);
    if (id !== "new") {
      let url = id
        ? "/" + entity.toLowerCase() + "/" + id
        : "/" + entity.toLowerCase();
      API_Request("GET", url)
        .then((result) => {
          setData(result.data[0]);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  }, [id]);

  if (data === undefined || (data.length < 1 && id !== "new")) {
    return (
      <Spin
        spinning={TextTrackCue}
        style={{ position: "fixed", right: 16, bottom: 16 }}
      />
    );
  }

  let render_aux = <></>;

  if (id === "new") {
    render_aux = (
      <>
        <h1>Add {module || entity.slice(0, -1)} </h1>
        {!loading && (
          <FormBuilder
            entity={entity.toLowerCase()}
            formData={false}
            formParams={dataTable_params.fieldsForm}
          />
        )}
      </>
    );
    return render_aux;
  }
  if (module === undefined) {
    if (id !== undefined) {
      render_aux = (
        <>
          <h1>
            {entity.slice(0, -1)}{" "}
            <code style={{ color: "#a2a2a2" }}>
              {GlobalConfig.API_BACKEND.replace("api", "") + "flow/" + data.tag}
            </code>
            <Tooltip title={"Copy url"}>
              <Icons.CopyOutlined
                style={{ cursor: "pointer" }}
                onClick={() =>
                  handleCopyToClipboard(
                    GlobalConfig.API_BACKEND.replace("api", "") +
                      "flow/" +
                      data.tag
                  )
                }
              />
            </Tooltip>
          </h1>
          <Row gutter={16}>
            <Col span={15}>
              {!loading && (
                <FormBuilder
                  entity={entity.toLowerCase()}
                  formData={data}
                  formParams={dataTable_params.fieldsForm}
                />
              )}
            </Col>
            <Col span={9}>
              <Row gutter={8}>
                <Col span={20} offset={2}>
                  <h1>Security</h1>
                  {data.security && (
                    <DataTable
                      loading={loading}
                      data={data.security}
                      params={dataTable_params_security}
                      context="onPage"
                      onClick={handle_RowSecondaryWindow}
                      onCreateClick={handle_OpentoCreateSecodaryWindow}
                    />
                  )}
                </Col>
                <Col span={20} offset={2}>
                  <h1>Webhooks</h1>
                  {data.webhook && (
                    <DataTable
                      loading={loading}
                      data={data.webhook}
                      params={dataTable_params_webhook}
                      context="onPage"
                      onClick={handle_RowSecondaryWindow}
                      onCreateClick={handle_OpentoCreateSecodaryWindow}
                    />
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
          {isSecondaryWindowVisible && (
            <FormSecondaryWindow
              title="Detail"
              data={secodary_data}
              onSave={handle_SaveSecodaryWindow}
              onClose={handle_CloseSecodaryWindow}
              onDelete={handle_DeleteSecodaryWindow}
            />
          )}
          <Modal
            title="Confirm Delete"
            open={deleteModalVisible}
            onOk={handle_DeleteConfirm}
            onCancel={handle_DeleteCancel}
          >
            <p>Are you sure you want to delete ?</p>
          </Modal>
        </>
      );
    } else {
      render_aux = (
        <>
          <h1>Flows</h1>
          <DataTable loading={loading} data={data} params={dataTable_params} />
        </>
      );
    }
    return render_aux;
  }

  switch (module) {
    case "security":
      render_aux = (
        <>
          <h1>Security</h1>
          {!loading && (
            <FormBuilder
              entity={entity.toLowerCase()}
              formData={data.security}
              formParams={dataTable_params_security.fieldsForm}
            />
          )}
        </>
      );
      break;
    default:
      render_aux = <></>;
  }

  return render_aux;
};

export default FlowsPage;
