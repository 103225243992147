import { createContext,  useState } from 'react';
import axios from "axios";
import { GlobalConfig } from '../helpers';
import { Spin } from 'antd';
export const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const [isLogged, setisLogged] = useState(false);
  const [loading, setloading] = useState(false);
  const [userData, setuserData] = useState({name:'',username:'',avatar:null,token:null});
  const _login = (username,password) => {
          // Lógica de inicio de sesión
          setloading(true);
          return axios({
                    method: 'POST',
                    url: GlobalConfig.API_BACKEND+"/login",
                    data: {
                              username: username,
                              password: password
                              }
          })
          .then((result) => {
                    let the_token = result.data.token;
                    window.localStorage.setItem("apiKey",the_token)
                    setisLogged (true)
                    setuserData({username:result.data.username,name:result.data.name,avatar:result.data.avatar,role:result.data.role,token:the_token,accounts:result.data.accounts,plan:result.data.plan})
                    setloading(false);
                    return true
          })
          .catch((error) => {
                     window.localStorage.removeItem("apiKey")
                    setisLogged (false)
                    setloading(false);
                    return false
          });
  };

  const _logout = () => {
    // Lógica de cierre de sesión
    setloading(true);
    setisLogged(false);
    setuserData({name:'',username:'',avatar:null,token:null})
    setloading(false);
    return true
  };

  const _alive = (token) => {
          setloading(true);
          return axios({
                    method: 'POST',
                    url: GlobalConfig.API_BACKEND+"/alive",
                    data: {token: token}
          })
          .then((result) => {
                    let the_token = result.data.token;
                    window.localStorage.setItem("apiKey",the_token)
                    setisLogged (true)
                    setuserData({username:result.data.username,name:result.data.name,avatar:result.data.avatar,role:result.data.role,token:the_token,accounts:result.data.accounts,plan:result.data.plan})
                    setloading(false);
                    return true
          })
          .catch((error) => {
                    console.log("No apiKey valid")
                    window.localStorage.removeItem("apiKey")
                    setisLogged (false)
                    setloading(false);
                    return false
          });
  }

  if (!isLogged && !loading &&  window.localStorage.getItem("apiKey") ) {
          _alive(window.localStorage.getItem("apiKey"))
  }

  const data = { isLogged,loading, _login, _logout, _alive,userData }

  if (loading) return <Spin spinning={TextTrackCue} style={{ position: 'fixed', left: 16, top: 16 }} />
  return (
    <AuthContext.Provider value={data}>
      {children}
    </AuthContext.Provider>
  );
};

