import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { AuthContextProvider } from "./Services/AuthService";
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from "react-router-dom";
import RenderLayout from "./Components/Layout";
import Home from "./Pages/Home";
import Login from "./Pages/Login";
import AccountsPage from "./Pages/Account";
import SessionsPage from "./Pages/Sessions";
import UsersPage from "./Pages/Users";
import FlowsPage from "./Pages/Flows";
import { Builder } from "./Components/Builder";
import { ProtectedRoute } from "./Components/ProtectedRoute";
import ProfilePage from "./Pages/Profile";
import CustomersPage from "./Pages/Customers";
import BotsPage from "./Pages/Bots";
import { NodesContextProvider } from "./contexts/NodesContext";

const router = createBrowserRouter([
  {
    element: <ProtectedRoute />,
    children: [
      {
        element: <RenderLayout />,
        children: [
          { index: true, element: <Home /> },
          { path: "home", element: <Home /> },
          { path: "profile", element: <ProfilePage /> },
          { path: "accounts", element: <AccountsPage /> },
          { path: "accounts/:id", element: <AccountsPage /> },
          { path: "customers", element: <CustomersPage /> },
          { path: "customers/:customerid", element: <CustomersPage /> },
          { path: "sessions", element: <SessionsPage /> },
          { path: "sessions/:id", element: <SessionsPage /> },
          { path: "users", element: <UsersPage /> },
          { path: "users/:id", element: <UsersPage /> },
          { path: "flows", element: <FlowsPage /> },
          { path: "flows/:id", element: <FlowsPage /> },
          { path: "flows/:module/:id", element: <FlowsPage /> },
          { path: "bots/builder/:id", element: <Builder /> },
          { path: "bots", element: <BotsPage /> },
          { path: "bots/:id", element: <BotsPage /> },
        ],
      },
    ],
  },
  {
    path: "login",
    element: <Login />,
  },
  {
    path: "*",
    element: <Navigate to="/home" />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <AuthContextProvider>
    <NodesContextProvider>
      <RouterProvider router={router} />
    </NodesContextProvider>
  </AuthContextProvider>
);
